@use "sass:map";
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
$font-family-base: 'OpenSan';

@import 'assets/fonts/fonts.css';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

$bnp-black-grey: (
  50: #979797,
  100: #838282,
  200: #6D7278,
  300: #3C332C,
  400: #393834,
  500: #32312E,
  600: #2C2B29,
  700: #212121,
  800: #000000,
  900: #000000,
  A100: #838282,
  A200: #6D7278,
  A400: #393834,
  A700: #212121,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$bnp-green: (
  50: #28C3A9,
  100: #e0f3f3,
  200: #00A882,
  300: #b1e2e0,
  400: #00A984,
  500: #00A984,
  600: #21716C,
  700: #007C79,
  800: #006072,
  900: #006072,
  A100: #CEE8E0,
  A200: #00A882,
  A400: #21716C,
  A700: #006072,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$bnp-purple-red: (
  50: #e73384,
  100: #e73384,
  200: #CD1515,
  300: #CD1515,
  400: #CD1515,
  500: #CD1515,
  600: #CD1515,
  700: #CD1515,
  800: #CD1515,
  900: #CD1515,
  A100: #e73384,
  A200: #e73384,
  A400: #CD1515,
  A700: #CD1515,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$bnp-white: (
  50: #F8F8F8,
  100: #F6F6F6,
  200: #EDEDED,
  300: #E5F6F2,
  400: #E0F3F3,
  500: #D9D9D9,
  600: #CECECE,
  700: #C1E8E7,
  800: #C1E8E7,
  900: #C1E8E7,
  A100: #F6F6F6,
  A200: #E0F3F3,
  A400: #E0F3F3,
  A700: #E0F3F3,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$mat-light-theme-background: map.deep-merge(
  $mat-light-theme-background,
  (
    background: map-get($bnp-white, 100)
  )
);

$typography: mat-typography-config(
  $font-family: 'OpenSans, monospace',
  $headline: mat-typography-level(32px, 38px, normal),
  $title: mat-typography-level(24px, 29px, bold),
  $subheading-2: mat-typography-level(21px, 21px, bold),
  $subheading-1: mat-typography-level(15px, 18px, bold),
  $body-1: mat-typography-level(12px, 15px, normal)
);

@mixin angular-application-theme($config-or-theme, $prefix: '') {
  $config: mat-get-color-config($config-or-theme);

  $properties: color, background-color, border-color;
  $directions: left, right, top, bottom;
  $step: 5;

  @each $palette-key, $palette-value in $config {
    @if type-of($palette-value) == 'map' {
        @each $property in $properties {
            @each $hue-key, $hue-value in $palette-value{
                @if type-of($hue-value) != 'map' {
                    @for $opacity from 0 to 100/$step+1 {
                        .#{$prefix}#{$property}-#{$palette-key}-#{$hue-key}-#{$opacity*$step} {
                            #{$property}: mat-color($palette-value, $hue-key, $opacity*$step/100)!important;
                        }
                    }
                    .#{$prefix}#{$property}-#{$palette-key}-#{$hue-key} {
                        #{$property}: mat-color($palette-value, $hue-key)!important;
                    }
                }
            }
            .#{$prefix}#{$property}-#{$palette-key} {
                #{$property}: mat-color($palette-value)!important;
            }
        }
        @each $direction in $directions {
            .#{$prefix}background-gradient-#{$direction}-#{$palette-key} {
                background: linear-gradient(to $direction,mat-color($palette-value,400),mat-color($palette-value,900))!important;
            }
        }
    }
  }
}

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$light-primary: mat-palette($bnp-green);
$light-accent: mat-palette($bnp-purple-red, A200, A100, A400);

// The warn palette is optional (defaults to red).
$light-warn: mat-palette($bnp-purple-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$light-theme: mat-light-theme((
  color: (
    primary: $light-primary,
    accent: $light-accent,
    warn: $light-warn,
  ),
  typography: $typography
));

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dark-primary: mat-palette($bnp-green);
$dark-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$dark-warn: mat-palette($mat-grey);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$dark-theme: mat-dark-theme((
  color: (
    primary: $dark-primary,
    accent: $dark-accent,
    warn: $dark-warn,
  ),
  typography: $typography
));


$shadow: 1px 2px 12px 0 rgb(37 59 57 / 20%);
$box-gradient: linear-gradient(90deg,#54c9c3 0,#54b5c9 100%);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($light-theme);
@include angular-application-theme($light-theme);
@include angular-application-theme($dark-theme, 'opposite-');
//@include mat-radio-color($dark-theme);
// @media (prefers-color-scheme: light) {
//   @include angular-material-theme($light-theme);
// }

// @media (prefers-color-scheme: dark) {
//   @include angular-material-theme($dark-theme);
// }

.opposite-theme {
  @include angular-material-color($dark-theme);
  @include angular-application-theme($dark-theme);
  @include angular-application-theme($light-theme, 'opposite-');
  @extend .mat-app-background;
}

/* You can add global styles to this file, and also import other style files */
.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: 'BNPPSans', monospace;
  font-size: 14px;
  font-weight: 500;
}

mat-horizontal-stepper{
  @extend .background-color-background-background;
}

mat-card{
  border-radius: 0px !important;
  &:not([class*=mat-elevation-z]){
    @extend .mat-elevation-z0;
    box-shadow: none!important;
  }
}
.mat-card-header-text {
  margin: 0;
}
.mat-card-header .mat-card-title {
  margin-bottom: 0 !important;
  h3 { margin-bottom: 0 !important; }
}
.mat-card-subtitle {
  font-size: 14px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 35px;
  margin-bottom: 16px;
}
mat-expansion-panel{
  border-radius: 0px !important;
  &:not([class*=mat-elevation-z]){
    @extend .mat-elevation-z0;
    box-shadow: $shadow!important;
  }
  &.no-shadow { box-shadow: none!important;}
}

.overlay {
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  height: 100vh;
  padding-top: 42vh;
  background: rgba(37, 59, 57, 0.2);
  z-index: 100;
}

.word-wrap-break {
  word-wrap: break-word;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.gradient {
  background: $box-gradient;
}
.shadow, mat-card:not([class*=mat-elevation-z]).shadow {
  box-shadow: $shadow!important;
}
.shadow.bnpp-title-shadow {
  border-radius: 12px!important;
  padding: 0!important;
  .mat-expansion-panel-header {
    border-radius: 12px 12px 0 0!important;
  }
}

.bnpp-title {
  padding: 10px;
  background: linear-gradient(90deg, #9ee1dd 0%, #9ed5e1 100%)!important;
  border-radius: 12px 12px 0 0;
  align-items: center;
  font-family: 'BNPPSans';
  font-size: 16px!important; 
  font-weight: 500!important;
  .mat-card-title, .mat-expansion-panel-header-title {
    font-family: 'BNPPSans';
    font-size: 16px!important; 
    font-weight: 500!important;
    line-height: 20px!important;
  }
}

.mat-horizontal-stepper-header-container {
  width: 60%;
  margin: 0 auto;
  min-width: 750px;
}

.popover {
  @extend .mat-app-background;
  .popover-body {
    color: inherit;
  }
}

.width-max {
  width: max-content;
}

.mat-panel-title, .mat-expansion-panel-header-title{
  font-weight: bold;
}

.mat-icon{
  width: auto !important;
}

svg {
  width: auto;
  overflow: visible;
}

.space{
  padding: 0px 20px;
}

button:not([mat-fab]){
  border-radius: 20px!important;
}

.card-form-logiciel{
  height: 50px;
}

html, body { height: 100%; background-color: #F6F6F6; }
body { margin: 0; font-family: 'OpenSans', "Helvetica Neue", sans-serif;}

h1, h2, h3, h4, .mat-h1, .mat-h2, .mat-h3, .mat-h4, .mat-h5, .mat-typography h1, .mat-typography h3, .mat-typography h2, .mat-typography h4 {
  font-family: 'BNPPSans';
}

.mat-typography h1 {
  font-weight: 700;
  @extend .color-primary;
}
.mat-typography h3 {
  font-weight: 500;
}
.mat-typography h4 {
  font-size: 14px;
  margin: 0 0 0;
}
.mat-typography h5 {
  font-size: 12px;
  font-weight: 700;
  margin: 0;
}

.mat-list-base .mat-list-item {
  font-size: 12px;
}
.box{
  padding:0 5px !important;
  padding-bottom: 10px !important;
  &:first-child{
    padding-left:0px !important;
  }
  &:last-child{
    padding-right: 0px !important;
  }
}
.cdk-overlay-dark-backdrop {
  background: rgb(0 100 115 / 38%);
}

.mat-tooltip-trigger {
    cursor: pointer;
}

.mat-tooltip {
  @extend .background-color-background-background;
  @extend .color-foreground-base;
  font-size: 14px;
  border: 1px solid;
  border-radius: 3px;
  
  &.primary {
      @extend .color-primary;
      @extend .border-color-primary;
  }
  &.accent {
      @extend .color-accent;
      @extend .border-color-accent;
  }
  &.warn {
      @extend .color-warn;
      @extend .border-color-warn;
  }
}
@keyframes flashing {
    0% { opacity: 1.0; }
    25% { opacity: 0.75; }
    50% { opacity: 0.5; }
    75% { opacity: 0.25; }
}
.flashing {
    animation-name: flashing;
    animation-duration: 1s;
    animation-iteration-count: 10;
    font-size: 200%;
}
.flashing-label { font-size: 150%; }