/* OpenSans */
@font-face {
    font-family: 'OpenSans';
    src: url('/assets/fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('/assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('/assets/fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('/assets/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('/assets/fonts/OpenSans/OpenSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

/* BNPP SANS */
@font-face {
    font-family: 'BNPPSans';
    src: url('/assets/fonts/BNPPSans/BNPPSans-Light.otf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'BNPPSans';
    src: url('/assets/fonts/BNPPSans/BNPPSans.otf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'BNPPSans';
    src: url('/assets/fonts/BNPPSans/BNPPSans-Bold.otf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'BNPPSans';
    src: url('/assets/fonts/BNPPSans/BNPPSans-ExtraBold.otf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'BNPPSans';
    src: url('/assets/fonts/BNPPSans/BNPPSans-Condv2.otf');
    font-weight: normal;
    font-style: normal;
    font-stretch: condensed;
}
